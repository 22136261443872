<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">ทำไมต้องซื้อกับ นรินทร์เภสัช</label>
      <img
        src="@/assets/images/banner/why-2-buy-banner.png"
        class="hidden-sm-and-down"
      >
    </div>
    <div class="info-paragraph">
      <ul>
        <li>เราส่งสินค้าทุกชนิด ทั้งของเหลว สินค้าหนัก สินค้าชิ้นใหญ่ เช่น เตียง , รถเข็น , Syringe , เกลือแร่ เป็นต้น</li>
        <li>เรารับเปลี่ยนคืนสินค้า 100% ทั้งกรณีสินค้าเสียหายจากการขนส่ง หรือสินค้าหมดอายุ</li>
        <li>ท่านสามารถทราบค่าส่งได้ทันทีที่หน้าเว็บไซต์ ไม่ต้องรอทางร้านแจ้ง ทำให้ท่านบริหารต้นทุนได้อย่างรวดเร็ว</li>
        <li>ทุกๆยอดการสั่งซื้อของท่านจะไม่สูญเปล่า เรามีระบบเก็บคะแนนในรูปของ Capsule Point บันทึกในระบบอัตโนมัติทุกยอดการสั่งซื้อของท่าน</li>
        <li>สินค้าใดที่เราไม่มีขาย เราสามารถจัดหาให้ท่านได้ ผ่านระบบหน้าเว็บได้อย่างสะดวกสบาย</li>
        <li>เราแยกหมวดหมู่สินค้าได้ชัดเจน ให้ท่านสามารถเลือกดูได้อย่างสะดวกสบาย</li>
        <li>ท่านสามารถดูข้อมูลสินค้า รูปสินค้า และราคา ได้ที่หน้าเว็บไซต์ได้ตลอดเวลา ข้อมูลครบถ้วน</li>
        <li>ท่านสามารถรู้ได้ทันทีว่าสินค้าตัวใดมีสินค้า หรือ สินค้าหมด</li>
        <li>เราบริการอย่างมืออาชีพ ด้วยความรวดเร็ว หากพนักงานติดต่อกลับล่าช้า ไม่สุภาพ ให้ข้อมูลไม่ครบถ้วน หรือติดขัดปัญหาด้านใด มีระบบบริการท่านในการแจ้งปัญหา ติชม แนะนำ ได้ทันทีหน้าเว็บไซต์ การติชมของท่านจะถูกส่งตรงสู่ผู้บริหารและนำไปปรับปรุงเสมอ</li>
        <li>เรามีระบบให้ท่านสามารถบันทึกสินค้าที่ใช้บ่อยไว้ใน List ของท่านเอง เพื่อความสะดวกในการสั่งซื้อสินค้าตัวเดิม</li>
        <li>ท่านสามารถดูประวัติการสั่งซื้อย้อนหลังได้ตลอดเวลา ผ่านระบบสมาชิกทางหน้าเว็บไซต์</li>
      </ul>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'Why2Buy',
};
</script>
<style scoped>
.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > ul {
    margin-top: 32px;
}

.info-paragraph > ul > li {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    margin-left: 32px;
}

</style>